import React, { useEffect, Fragment, useState } from 'react';
import axios from 'axios';
import { Segment } from 'semantic-ui-react';
import PieceSegment from './PieceSegment';
import PieceModal from './PieceModal';
import { useAuth0 } from '../react-auth0-spa';
import { authHeaders } from '../lib/authorization';

const PiecesList = (props) => {
  const [currentPiece, setCurrentPiece] = useState();
  const [pieces, setPieces] = useState(props.pieces);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    setPieces(props.pieces);
  }, [props.pieces])

  const deletePiece = async (piece) => {
    const token = await getTokenSilently();

    try {
      await axios.delete(`api/v1/pieces/${piece.id}.json`, authHeaders(token));

      props.onDelete(piece.id);
    } catch (error) {
      console.log(error);
    }
  }

  const open = (piece) => {
    setCurrentPiece(piece);
  }

  const close = () => {
    setCurrentPiece(undefined);
  }

  const save = async (piece) => {
    const token = await getTokenSilently();

    const data = {
      piece: {
        name: piece.name,
        composer: piece.composer,
      }
    };

    try {
      const response = await axios.put(`api/v1/pieces/${piece.id}.json`, data, authHeaders(token));
      props.onUpdate(response.data);
      setCurrentPiece(undefined);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      {pieces.map((piece, i) => <PieceSegment piece={piece} onDelete={deletePiece} onEdit={open} key={`piece-segment-${i}`} showDelete={props.showDelete} showEdit={props.showEdit} />)}
      {pieces.length === 0 && <Segment attached>No pieces yet.</Segment>}
      {currentPiece && <PieceModal piece={currentPiece} onClose={close} onSave={save} />}
    </Fragment>
  );
};

export default PiecesList;
