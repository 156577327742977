import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Container, Header, Segment, Loader, Button, Grid } from 'semantic-ui-react';
import moment from "moment";
import axios from "axios";
import { useAuth0 } from "../react-auth0-spa";
import PracticeSegment from '../components/PracticeSegment';
import PracticeModal from '../components/PracticeModal';
import ResponsiveContainer from "../components/ResponsiveContainer";
import { usePracticeTypes } from "../contexts/PracticeTypesContext";
import { authHeaders } from '../lib/authorization';
import { addToCollection, updateItemInCollection, deleteFromCollection } from '../lib/filter';
import { week, isToday, isInTheFuture } from '../services/practicesService';
import Summary from '../components/practiceJournal/Summary';
import PageHeader from '../components/PageHeader';
import { PiecesProvider } from "../contexts/PiecesContext";

const PracticeJournal = (props) => {
  const [practices, setPractices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPractice, setCurrentPractice] = useState();
  const [error, setError] = useState();
  const [current, setIsCurrent] = useState(moment());
  const { getTokenSilently } = useAuth0();
  const { practiceTypes, practiceTypeOptions } = usePracticeTypes();

  const refresh = useCallback(async () => {
    const token = await getTokenSilently();

    try {
      const response = await week(current, token);

      setPractices(response.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }, [current, getTokenSilently]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const newPractice = (day) => {
    setCurrentPractice({
      description: '',
      duration: '',
      note_id: '',
      mode: '',
      bpm: '',
      piece_id: '',
      dated_on: day.format("YYYY-MM-DD")
    });
  }

  const open = (practice) => {
    setCurrentPractice(practice);
  }

  const close = () => {
    setCurrentPractice();
  }

  const create = async (practice) => {
    const data = {
      practice
    };

    const token = await getTokenSilently();

    try {
      const response = await axios.post(`api/v1/practices.json`, data, authHeaders(token))
      const newPractices = addToCollection(practices, response.data, 'dated_on');

      setCurrentPractice();
      setPractices(newPractices);
      setError();
    } catch (error) {
      if (error.response) {
        setError(error.response.data);
      }
      console.log(error);
    }
  }

  const update = async (practice) => {
    const data = {
      practice
    };

    const token = await getTokenSilently();

    try {
      const response = await axios.put(`api/v1/practices/${practice.id}.json`, data, authHeaders(token))
      const newPractices = updateItemInCollection(practices, response.data);

      setCurrentPractice();
      setPractices(newPractices);
      setError();
    } catch (error) {
      setError(error.response.data);
      console.log(error);
    }
  }

  const save = async (practice) => {
    if (practice.id) {
      await update(practice);
    } else {
      await create(practice);
    }
  }

  const deletePractice = async (practice) => {
    const token = await getTokenSilently();

    try {
      await axios.delete(`api/v1/practices/${practice.id}.json`, authHeaders(token));
      const newPractices = deleteFromCollection(practices, practice.id);

      setPractices(newPractices);
    } catch (error) {
      console.log(error);
    }
  }

  const back = () => {
    const newCurrent = current.clone().add(-7, "days");
    setIsCurrent(newCurrent);;
  }

  const forward = () => {
    const newCurrent = current.clone().add(7, "days");
    setIsCurrent(newCurrent);
  }

  const formatDay = (day) => {
    const formatted = day.format("dddd, LL");
    if (isToday(day)) {
      return `Today, ${formatted}`;
    }

    return formatted;
  }

  const renderModal = () => {
    if (!currentPractice) {
      return;
    }

    if (practiceTypeOptions.length === 0) {
      return;
    }

    return <PracticeModal practice={currentPractice} error={error} onSave={save} onClose={close} />;
  }

  const renderDays = () => {
    if (isLoading) {
      return;
    }

    if (practiceTypes.length === 0) {
      return;
    }

    const days = [];
    for (let i = 0; i < 7; i++) {
      let currentDay = moment(current.clone()).startOf('isoWeek').add(i, "days");
      let day = practices[currentDay.format("YYYY-MM-DD")];
      let inTheFuture = isInTheFuture(currentDay);
      let report;

      if (day && day.length > 0) {
        report = day.map((element, index) =>
          <PracticeSegment practice={element} key={`practice-${element.id}`} onEdit={open} onDelete={deletePractice} />
        );
      } else {
        report = <Segment attached textAlign='center' disabled={inTheFuture} key={`practice-empty-${i}`}>No practice time recorded.</Segment>;
      }

      let header = <Segment secondary disabled={inTheFuture} attached key={`day-${i}`}><Header size="small">{formatDay(currentDay)}</Header></Segment>;
      let add = <Segment attached textAlign='center' key={`day-add-${i}`}><Button icon='add' content='Add Practice Time' labelPosition='left' primary onClick={() => newPractice(currentDay)} /></Segment>;
      days.push(header);
      days.push(report);

      if (!inTheFuture) {
        days.push(add);
      }
    }

    return days;
  }

  return (
    <PiecesProvider>
      <ResponsiveContainer {...props}>
        <Container>
          <PageHeader icon="calendar alternate" title="Journal" subtitle="See your progress at a glance." />

          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={10}>
                <Segment.Group raised>
                  <Segment attached color="blue" textAlign="center" clearing={true}>
                    <Button icon="angle left" floated="left" onClick={back} />
                    <Header as="span">
                      {current.clone().startOf('isoWeek').format("DD MMM YYYY")}&mdash;
                  {current.clone().startOf('isoWeek').add(6, "days").format("DD MMM YYYY")}
                    </Header>
                    <Button icon="angle right" floated="right" onClick={forward} />
                  </Segment>

                  <Fragment>
                    {renderDays()}
                    {renderModal()}
                  </Fragment>
                </Segment.Group>
                <Loader active={isLoading}></Loader>
              </Grid.Column>
              <Grid.Column width={6}>
                <Summary practices={practices} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </ResponsiveContainer>
    </PiecesProvider>
  );
}

export default PracticeJournal;
