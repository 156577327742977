import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Container, Responsive, Sidebar, Icon, Image } from 'semantic-ui-react';
import Footer from "./Footer";
import { useAuth0 } from "../react-auth0-spa";

const getWidth = () => {
  return window.innerWidth;
}

const propsForMenuItem = (name, to, location) => {
  return { name, to, as: Link, active: location.pathname === to };
}

const DesktopContainer = (props) => {
  const { logout, user } = useAuth0();

  return (
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth} className="wrapper">
      <div className="content">
        <Menu inverted borderless>
          <Container>
            <Menu.Item><Image src="/images/logo-white.svg" size="small" /></Menu.Item>
            <Menu.Item {...propsForMenuItem("home", "/", props.location)} />
            <Menu.Item {...propsForMenuItem("goals", "/goals", props.location)} />
            <Menu.Item {...propsForMenuItem("journal", "/journal", props.location)} />
            <Menu.Item {...propsForMenuItem("timer", "/timer", props.location)} />
            <Menu.Item {...propsForMenuItem("pieces", "/pieces", props.location)} />
            <Menu.Item {...propsForMenuItem("insights", "/insights", props.location)} />
            <Menu.Menu position='right'>
              <Dropdown item text="Help">
                <Dropdown.Menu>
                  <Dropdown.Item {...propsForMenuItem("support", "/support", props.location)}>Get Support</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown item text={user.name}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Container>
        </Menu>
        {props.children}
      </div>
      <Footer />
    </Responsive>
  );
};

const MobileContainer = (props) => {
  const { logout, user } = useAuth0();
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const openSidebar = () => {
    window.scrollTo(0, 0);
    setSidebarOpened(true);
  }

  return (
    <Responsive
      getWidth={getWidth}
      maxWidth={Responsive.onlyMobile.maxWidth}
    >
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          direction='right'
          animation='overlay'
          onHide={() => setSidebarOpened(false)}
          vertical
          inverted
          visible={sidebarOpened}
        >
          <Menu.Item header>My Piano Practice</Menu.Item>
          <Menu.Item {...propsForMenuItem("home", "/", props.location)} />
          <Menu.Item {...propsForMenuItem("goals", "/goals", props.location)} />
          <Menu.Item {...propsForMenuItem("journal", "/journal", props.location)} />
          <Menu.Item {...propsForMenuItem("timer", "/timer", props.location)} />
          <Menu.Item {...propsForMenuItem("pieces", "/pieces", props.location)} />
          <Menu.Item {...propsForMenuItem("insights", "/insights", props.location)} />
          <Menu.Item header>Help</Menu.Item>
          <Menu.Item {...propsForMenuItem("support", "/support", props.location)}>Get Support</Menu.Item>
          <Menu.Item header>{user.name}</Menu.Item>
          <Menu.Item onClick={() => logout()}>Logout</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <div style={{ paddingTop: '1em', paddingBottom: '4em' }}>
            {props.children}

            <Footer />
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>

      <Menu icon='labeled' fixed='bottom' fluid widths={5} inverted>
        <Menu.Item {...propsForMenuItem("home", "/", props.location)}>
          <Icon name='home' />
          Home
        </Menu.Item>
        <Menu.Item {...propsForMenuItem("goals", "/goals", props.location)}>
          <Icon name='target' />
          Goals
        </Menu.Item>
        <Menu.Item {...propsForMenuItem("journal", "/journal", props.location)}>
          <Icon name='calendar alternate' />
          Journal
        </Menu.Item>
        <Menu.Item {...propsForMenuItem("timer", "/timer", props.location)}>
          <Icon name='stopwatch' />
          Timer
        </Menu.Item>
        <Menu.Item onClick={openSidebar}>
          <Icon name='sidebar' />
          More
        </Menu.Item>
      </Menu>
    </Responsive>
  );
};

function ResponsiveContainer(props) {
  return (
    <Fragment>
      <div style={{ height: '100%' }}>
        <DesktopContainer {...props}>{props.children}</DesktopContainer>
        <MobileContainer  {...props}>{props.children}</MobileContainer>
      </div>
    </Fragment>
  );
}

export default ResponsiveContainer;
