import axios from "axios";
import { authHeaders } from "../lib/authorization";

export const all = (token) => {
  return axios.get(`api/v1/goals/all.json`, authHeaders(token))
}

export const create = (goal, token) => {
  const data = {
    goal: {
      name: goal.name,
    }
  };

  return axios.post(`api/v1/goals.json`, data, authHeaders(token))
}
