import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Header, Icon } from 'semantic-ui-react';
import { useAuth0 } from "../react-auth0-spa";
import { usePracticeTypes } from "../contexts/PracticeTypesContext";
import { useNotes } from "../contexts/NotesContext";
import { usePieces } from "../contexts/PiecesContext";
import PieceModal from './PieceModal';
import { create as pieceCreate } from '../services/piecesService';

const PracticeModal = (props) => {
  const [practice, setPractice] = useState(props.practice);
  const [error, setError] = useState(props.error);
  const [currentPiece, setCurrentPiece] = useState();
  const [pieceError, setPieceError] = useState();
  const { practiceTypeOptions, practiceTypes } = usePracticeTypes();
  const { getTokenSilently } = useAuth0();
  const { noteOptions } = useNotes();
  const { pieceOptions, isLoading: piecesLoading, setLastUpdate } = usePieces();

  const modeOptions = [
    { key: "major", text: "Major", value: "major" },
    { key: "minor", text: "Minor", value: "minor" },
  ];

  useEffect(() => {
    setError(props.error);
  }, [props.error])

  const handleChange = (_event, { name, value }) => {
    setPractice({
      ...practice,
      [name]: value
    });
  }

  const errorFor = (field) => {
    if (!error || !error[field]) {
      return;
    }

    return error[field].join(", ");
  }

  const isSelected = (practiceTypeName) => {
    const found = practiceTypes.find((x) => x.name === practiceTypeName);
    if (!found) return false;

    return found.id === practice.practice_type_id;
  }

  const onSavePiece = async (piece) => {
    const token = await getTokenSilently();

    try {
      const response = await pieceCreate(piece, token);
      setLastUpdate(new Date());
      setCurrentPiece();
      setPieceError();
      setPractice({
        ...practice,
        piece_id: response.data.id,
      });
    } catch (error) {
      setPieceError(error.response.data);
      console.log(error);
    }
  }

  const keyFields = () => (
    <Form.Group widths="equal">
      <Form.Dropdown
        label="Note"
        name="note_id"
        options={noteOptions}
        value={practice.note_id}
        onChange={handleChange}
        selection
        clearable
        loading={piecesLoading}
        error={errorFor("note")}
      />

      <Form.Dropdown
        label="Mode"
        name="mode"
        options={modeOptions}
        value={practice.mode}
        onChange={handleChange}
        selection
        clearable
        error={errorFor("mode")} />
    </Form.Group>
  );

  const tempoFields = () => (
    <Form.Input
      label="Tempo"
      placeholder="(BPM)"
      name="bpm"
      value={practice.bpm}
      onChange={handleChange}
      type="number"
      width={4}
      error={errorFor("bpm")}
    />
  );

  const pieceFields = () => (
    <div>
      <Form.Dropdown
        label={
          <label>
            Piece <Button type="button" compact icon basic size="tiny" onClick={() => setCurrentPiece({ name: '', composer: '' })}>
              <Icon name="plus" />Add New
            </Button>
          </label>}
        name="piece_id"
        options={pieceOptions}
        value={practice.piece_id}
        onChange={handleChange}
        selection
        error={errorFor("piece")}
        clearable
      />
      {currentPiece && <PieceModal piece={currentPiece} onClose={() => setCurrentPiece()} onSave={onSavePiece} error={pieceError} />}
    </div>
  );

  return (
    <Modal
      open={!!practice}
      closeOnEscape
      closeOnDimmerClick
      onClose={props.onClose}
      size="small"
    >
      <Modal.Header>{practice.id ? "Update" : "New"} Practice Time</Modal.Header>
      <Modal.Content>
        <Form onSubmit={() => props.onSave(practice)}>
          <Form.Group widths="equal">
            <Form.Dropdown
              label="Type"
              name="practice_type_id"
              options={practiceTypeOptions}
              value={practice.practice_type_id}
              onChange={handleChange}
              selection
              error={errorFor("practice_type")}
              required
            />
            <Form.Input
              label="Duration"
              placeholder="(minutes)"
              name="duration"
              value={practice.duration}
              onChange={handleChange}
              type="number"
              error={errorFor("duration")}
              required
            />
          </Form.Group>

          <Form.TextArea
            label="Description"
            name="description"
            value={practice.description}
            error={errorFor("description")}
            onChange={handleChange} />

          {isSelected("scale") && <Header size="tiny" dividing>Scale Details (optional)<Header.Subheader>Enter more details about your scale practice for better reporting.</Header.Subheader></Header>}
          {isSelected("scale") && keyFields()}
          {isSelected("scale") && tempoFields()}

          {isSelected("piece") && <Header size="tiny" dividing>Piece Details (optional)<Header.Subheader>Enter more details about your scale practice for better reporting.</Header.Subheader></Header>}
          {isSelected("piece") && pieceFields()}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => props.onSave(practice)}>Save</Button>
        <Button onClick={props.onClose}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PracticeModal;
