import React, { createContext, useContext } from "react";
import Rollbar from 'rollbar';

const MonitoringContext = createContext({});

export const MonitoringContextConsumer = MonitoringContext.Consumer;
export const useMonitoring = () => useContext(MonitoringContext);

export const MonitoringProvider = (props) => {
  const rollbar = new Rollbar({
    accessToken: 'f8d2aaf5633d4beea60b260aea5fe201',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NODE_ENV === 'production',
    payload: {
      environment: process.env.NODE_ENV
    }
  });

  return (
    <MonitoringContext.Provider value={{rollbar}}>
      {props.children}
    </MonitoringContext.Provider>
  );
};
