import axios from "axios";
import { authHeaders } from "../lib/authorization";

export const create = (supportRequest, token) => {
  const data = {
    support_request: {
      type: supportRequest.type,
      subject: supportRequest.subject,
      message: supportRequest.message,
    }
  };

  return axios.post(`api/v1/support_requests.json`, data, authHeaders(token))
}
