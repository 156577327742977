import React, { useState, useEffect } from 'react';
import { Button, Container, Loader, Header, Segment } from 'semantic-ui-react';
import { useAuth0 } from "../react-auth0-spa";
import PiecesList from '../components/PiecesList';
import PieceModal from '../components/PieceModal';
import ResponsiveContainer from '../components/ResponsiveContainer';
import { excludeById, updateWithItem } from '../lib/filter';
import { all, create } from '../services/piecesService';
import PageHeader from '../components/PageHeader';

const Pieces = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pieces, setPieces] = useState([]);
  const [currentPiece, setCurrentPiece] = useState();
  const [error, setError] = useState();
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchPieces = async () => {
      const token = await getTokenSilently();

      try {
        const response = await all(token);
        setPieces(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    }

    fetchPieces();
  }, [getTokenSilently]);

  const onDelete = (pieceId) => {
    const newPieces = pieces.filter(excludeById(pieceId));
    setPieces(newPieces);
  }

  const onUpdate = (piece) => {
    const newPieces = pieces.map(updateWithItem(piece));
    setPieces(newPieces);
  }

  const onSave = async (piece) => {
    const token = await getTokenSilently();

    try {
      const response = await create(piece, token);
      const newPieces = pieces.concat(response.data);
      setPieces(newPieces);
      setCurrentPiece();
      setError();
    } catch (error) {
      setError(error.response.data);
      console.log(error);
    }
  }

  return (
    <ResponsiveContainer {...props}>
      <Container>
        <PageHeader icon="book" title="Pieces" subtitle="A collection of pieces you have mastered or keen to learn." />

        <Loader active={isLoading}></Loader>

        <Segment.Group raised>
          <Segment attached color='blue'>
            <Header>Pieces</Header>
          </Segment>
          <PiecesList pieces={pieces} onDelete={onDelete} onUpdate={onUpdate} />
          <Segment attached textAlign="center">
            <Button icon='add' content='New Piece' labelPosition='left' primary onClick={() => setCurrentPiece({ name: '', composer: '' })} />
            {currentPiece && <PieceModal piece={currentPiece} onClose={() => setCurrentPiece()} onSave={onSave} error={error} />}
          </Segment>
        </Segment.Group>

      </Container>
    </ResponsiveContainer>
  )
};

export default Pieces;
