import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';

const GoalModal = (props) => {
  const [goal, setGoal] = useState(props.goal);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
  }, [props.error])

  const handleChange = (_event, { name, value }) => {
    setGoal({
      ...goal,
      [name]: value
    });
  }

  const errorFor = (field) => {
    if (!error || !error[field]) {
      return;
    }

    return error[field].join(", ");
  }

  return (
    <Modal
      open={!!goal}
      closeOnEscape
      closeOnDimmerClick
      onClose={props.onClose}
      size="small"
    >
      <Modal.Header>{goal.id ? "Update" : "New"} Goal</Modal.Header>
      <Modal.Content>
        <Form onSubmit={() => props.onSave(goal)}>
          <Form.Input
            label="Name"
            name="name"
            value={goal.name}
            onChange={handleChange}
            error={errorFor("name")}
          />

        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => props.onSave(goal)}>Save</Button>
        <Button onClick={props.onClose}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default GoalModal;
