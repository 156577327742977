import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "../react-auth0-spa";
import { authHeaders } from "../lib/authorization";

const PracticeTypesContext = createContext({});

export const PracticeTypesConsumer = PracticeTypesContext.Consumer;
export const usePracticeTypes = () => useContext(PracticeTypesContext);

export const PracticeTypesProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [practiceTypes, setPracticeTypes] = useState([]);
  const [practiceTypeOptions, setPracticeTypeOptions] = useState([]);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchPracticeTypes() {
      const token = await getTokenSilently();

      try {
        const response = await axios.get('api/v1/practice_types.json', authHeaders(token));
        const options = response.data.map((value) => {
          return {
            key: value.id,
            text: value.display_name,
            value: value.id,
          };
        });

        setPracticeTypes(response.data);
        setPracticeTypeOptions(options);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchPracticeTypes();
  }, [getTokenSilently]);

  return (
    <PracticeTypesContext.Provider
      value={{practiceTypes, practiceTypeOptions, isLoading}}>
      {props.children}
    </PracticeTypesContext.Provider>
  );
}
