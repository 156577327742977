import React from 'react';
import { Container } from 'semantic-ui-react';
import ResponsiveContainer from '../components/ResponsiveContainer';
import PageHeader from '../components/PageHeader';
import Report from '../components/Report';

const Insights = (props) => {
  return (
    <ResponsiveContainer {...props}>
      <Container>
        <PageHeader icon="chart area" title="Insights" subtitle="Gain insight into how you practice and how to improve." />

        <Report />
      </Container>
    </ResponsiveContainer>
  )
};

export default Insights;
