import React, { Fragment, useState, useEffect } from 'react';
import { Header, Loader, Segment } from 'semantic-ui-react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import axios from 'axios';
import moment from 'moment';
import { useAuth0 } from "../react-auth0-spa";
import { findById } from "../lib/find";
import { COLOR_CODES } from "../lib/colors";
import { usePracticeTypes } from "../contexts/PracticeTypesContext";
import { authHeaders } from '../lib/authorization';

const Report = (props) => {
  const [practices, setPractices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getTokenSilently } = useAuth0();
  const { isLoading: consumerIsLoading, practiceTypes } = usePracticeTypes();

  useEffect(() => {
    async function fetchReport() {
      const token = await getTokenSilently();

      try {
        const response = await axios.get(`api/v1/practices/report.json`, authHeaders(token));
        setPractices(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    }

    fetchReport();
  }, [getTokenSilently]);

  const weeklyReport = () => {
    if (practices.length === 0) {
      return [];
    }

    const data = [];

    for (let i = 0; i < 14; i++) {
      let currentDay = moment().add(-13, 'days').add(i, 'day');
      let day = practices[currentDay.format("YYYY-MM-DD")];

      var result = [];

      if (day) {
        day.reduce((res, value) => {
          if (!res[value.practice_type_id]) {
            res[value.practice_type_id] = { id: value.practice_type_id, duration: 0 };
            result.push(res[value.practice_type_id])
          }
          res[value.practice_type_id].duration += value.duration;
          return res;
        }, {});
      }

      const row = {
        name: currentDay.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? "Today" : currentDay.format("YYYY-MM-DD")
      };

      result.forEach((element) => {
        row[element.id] = element.duration;
      });

      data.push(row);
    }

    return data;
  }

  return (
    <Fragment>
      <Loader active={consumerIsLoading || isLoading}></Loader>
      <Segment.Group raised>
        <Segment attached color="blue">
          <Header>
            Practice Time Per Day
            </Header>
        </Segment>
        <Segment attached>
          <ResponsiveContainer minHeight={300}>
            <BarChart data={weeklyReport()}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis label={{ value: 'Minutes', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend verticalAlign="top" iconType="circle" height={36} />
              {!isLoading && practiceTypes.map((practice_type, index) => (
                <Bar dataKey={practice_type.id} stackId="a" key={index} fill={COLOR_CODES[practice_type.name]} name={findById(practiceTypes, practice_type.id).display_name} />
              ))}
              }
            </BarChart>
          </ResponsiveContainer>
        </Segment>
      </Segment.Group>
    </Fragment>
  )
}
export default Report;
