import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { useAuth0 } from "../react-auth0-spa";
import { authHeaders } from "../lib/authorization";

const PiecesContext = createContext({});

export const PiecesConsumer = PiecesContext.Consumer;
export const usePieces = () => useContext(PiecesContext);

export const PiecesProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [pieces, setPieces] = useState([]);
  const [pieceOptions, setPieceOptions] = useState([]);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchPieces() {
      const token = await getTokenSilently();

      try {
        const response = await axios.get('api/v1/pieces.json', authHeaders(token));
        const options = response.data.map((value) => {
          return {
            key: value.id,
            text: _.compact([value.name, value.composer]).join(" - "),
            value: value.id,
          };
        });

        setPieces(response.data);
        setPieceOptions(options);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchPieces();
  }, [getTokenSilently, lastUpdate]);

  return (
    <PiecesContext.Provider
      value={{pieces, pieceOptions, isLoading, setLastUpdate}}>
      {props.children}
    </PiecesContext.Provider>
  );
}
