import React from 'react';
import _ from 'lodash';
import { Segment, Button } from 'semantic-ui-react';

const PieceSegment = (props) => {
  const piece = props.piece;
  const showDelete = props.showDelete === undefined ? true : props.showDelete;
  const showEdit = props.showEdit === undefined ? true : props.showEdit;

  const displayName = (piece) => {
    return _.compact([piece.name, piece.composer]).join(" - ");
  };

  return (
    <Segment attached key={`piece-${piece.id}`} clearing>
      {displayName(piece)}
      {showDelete && <Button icon="delete" basic floated='right' circular size="small" onClick={() => props.onDelete(piece)} />}
      {showEdit && <Button icon="pencil" basic floated='right' circular size="small" onClick={() => props.onEdit(piece)} />}
    </Segment>
  );
};

export default PieceSegment;
