import React, { useEffect, useState } from "react";
import { Segment, Header, Label, List, Loader } from "semantic-ui-react";
import _ from "lodash";
import { usePracticeTypes } from "../../contexts/PracticeTypesContext";
import { COLOR_NAMES } from "../../lib/colors";
import { findById } from "../../lib/find";

const Summary = (props) => {
  const [practices, setPractices] = useState(props.practices);
  const { isLoading, practiceTypes } = usePracticeTypes();

  useEffect(() => {
    setPractices(props.practices);
  }, [props.practices]);

  const total = (practices) => {
    let result = _.sumBy(_.flatMap(practices), 'duration');

    return result;
  }

  const renderSummaryByPracticeType = (practices) => {
    if (practiceTypes.length === 0) {
      return;
    }

    const summary = _.reduce(_.groupBy(_.flatMap(practices), 'practice_type_id'), (result, value, key) => {
      result[key] = _.sumBy(value, 'duration');
      return result;
    }, {});

    const items = [];
    for (const key of Object.keys(summary)) {
      items.push(<List.Item key={`practice-type-summary-${key}`}>
        <Label color={COLOR_NAMES[findById(practiceTypes, parseInt(key)).name]} horizontal>
          {findById(practiceTypes, parseInt(key)).display_name}
          <Label.Detail>{summary[key]} mins</Label.Detail>
        </Label>
      </List.Item>);
    }

    return items;
  }

  if (isLoading) {
    return <Loader active={true} />
  }

  return (
    <Segment.Group raised>
      <Segment attached color="blue" textAlign="center" clearing={true}>
        <Header>Summary</Header>
      </Segment>
      <Segment attached textAlign='center'>
        <p>During the selected period you practied for a total of:</p>
        <Label color="black" basic pointing size='big'>{total(practices)} mins</Label>
        <hr />
        <List horizontal>
          {renderSummaryByPracticeType(practices)}
        </List>
      </Segment>
    </Segment.Group>
  );
}

export default Summary;
