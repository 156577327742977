import React from 'react';
import { Segment, List, Grid } from 'semantic-ui-react';
import "./Footer.css"

class Footer extends React.Component {
  render() {
    return (
      <Segment inverted color="blue" padded='very' className="footer">
        <Grid container columns="equal" stackable>
          <Grid.Row>
            <Grid.Column>
              <List inverted>
                <List.Item><List.Header>My Piano Practice</List.Header></List.Item>
                <List.Item>
                  <List.Icon name="globe" />
                  <List.Content><a href="https://tobiashaar.com" className="footer-link">Website</a></List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="comment" />
                  <List.Content><a href="https://tobiashaar.com" className="footer-link">Contact Us</a></List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="question circle" />
                  <List.Content><a href="/support" className="footer-link">Get Support</a></List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="info circle" />
                  <List.Content><a href="https://tobiashaar.com/about" className="footer-link">About</a></List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List inverted>
                <List.Item><List.Header>Social</List.Header></List.Item>
                <List.Item>
                  <List.Icon name="mail" />
                  <List.Content><a href="mailto:support@tobiashaar.com" className="footer-link">Email</a></List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="feed" />
                  <List.Content><a href="https://tobiashaar.com" className="footer-link">Blog</a></List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p className="copyright">Copyright &copy; 2020-2021 by Tobias Haar</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Footer;
