import React from 'react';
import { Segment, List, Image, Dropdown, Header } from 'semantic-ui-react';
import _ from "lodash";
import { findById } from "../lib/find";
import { COLOR_CODES } from "../lib/colors";
import { usePracticeTypes } from "../contexts/PracticeTypesContext";
import { useNotes } from "../contexts/NotesContext";
import { usePieces } from "../contexts/PiecesContext";
import "./PracticeSegment.css";

const PracticeSegment = (props) => {
  const { practiceTypes } = usePracticeTypes();
  const { notes } = useNotes();
  const { pieces } = usePieces();

  const practice = props.practice;
  const showDelete = props.showDelete === undefined ? true : props.showDelete;
  const showEdit = props.showEdit === undefined ? true : props.showEdit;

  const renderIcon = (practice) => {
    if (practiceTypes.length === 0) {
      return;
    }

    const name = findById(practiceTypes, practice.practice_type_id).name;

    return <Image src={`/images/${name}.svg`} avatar />;
  }

  const renderLabel = (practice) => {
    if (practiceTypes.length === 0) {
      return;
    }

    const practiceType = findById(practiceTypes, practice.practice_type_id);
    const color = COLOR_CODES[practiceType.name];

    return <span style={{ color }}>{practiceType.display_name}</span>;
  }

  const renderNote = (practice) => {
    if (notes.length === 0) {
      return;
    }

    const found = findById(notes, practice.note_id);
    if (!found) {
      return;
    }

    const result = [];
    result.push(found.name);
    if (practice.mode) {
      result.push(practice.mode);
    }

    return result.join(" ");
  }

  const renderPiece = (practice) => {
    if (pieces.length === 0) {
      return;
    }

    const found = findById(pieces, practice.piece_id);
    if (!found) {
      return;
    }

    return _.compact([found.name, found.composer]).join(" - ");
  }

  const isOfType = (practiceTypeName) => {
    const found = practiceTypes.find((x) => x.name === practiceTypeName);
    if (!found) return false;

    return found.id === practice.practice_type_id;
  }

  return (
    <Segment attached key={`practice-${practice.id}`}>
      <div className="flex">
        <div className="flex-none">
          {renderIcon(practice)}
        </div>

        <div className="flex-stretch practice-content">
          <Header size="small">
            {renderLabel(practice)}
            <Header.Subheader>
              {practice.duration} mins
            </Header.Subheader>
          </Header>
          {practice.description &&
            <p className="description">
              {practice.description}
            </p>
          }

          {(practice.piece_id || practice.note_id || practice.bpm) &&
            <List horizontal bulleted className="meta">
              {isOfType("piece") && practice.piece_id && <List.Item>{renderPiece(practice)}</List.Item>}
              {isOfType("scale") && practice.note_id && <List.Item>{renderNote(practice)}</List.Item>}
              {isOfType("scale") && practice.bpm && <List.Item>{`${practice.bpm} BPM`}</List.Item>}
            </List>
          }
        </div>

        {(showEdit || showDelete) &&
          <div className="flex-none">
            <Dropdown
              icon='ellipsis horizontal'
              floating
              basic
              button
              direction="left"
              className='icon'
            >
              <Dropdown.Menu>
                {showEdit && <Dropdown.Item icon="pencil" text="Edit" onClick={() => props.onEdit(practice)} />}
                {showDelete && <Dropdown.Item icon="trash" text="Delete" onClick={() => props.onDelete(practice)} />}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      </div>
    </Segment>
  );
};

export default PracticeSegment;
