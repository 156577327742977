import React, { useEffect, Fragment, useState } from 'react';
import axios from 'axios';
import { Segment } from 'semantic-ui-react';
import GoalSegment from './GoalSegment';
import GoalModal from './GoalModal';
import { useAuth0 } from '../react-auth0-spa';
import { authHeaders } from '../lib/authorization';

const GoalsList = (props) => {
  const [currentGoal, setCurrentGoal] = useState();
  const [goals, setGoals] = useState(props.goals);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    setGoals(props.goals);
  }, [props.goals])

  const deleteGoal = async (goal) => {
    const token = await getTokenSilently();

    try {
      await axios.delete(`api/v1/goals/${goal.id}.json`, authHeaders(token));

      props.onDelete(goal.id);
    } catch (error) {
      console.log(error);
    }
  }

  const open = (goal) => {
    setCurrentGoal(goal);
  }

  const close = () => {
    setCurrentGoal(undefined);
  }

  const save = async (goal) => {
    const token = await getTokenSilently();

    const data = {
      goal: {
        name: goal.name,
      }
    };

    try {
      const response = await axios.put(`api/v1/goals/${goal.id}.json`, data, authHeaders(token));
      props.onUpdate(response.data);
      setCurrentGoal(undefined);
    } catch (error) {
      console.log(error);
    }
  }

  const toggleGoal = async (goal, checked) => {
    const token = await getTokenSilently();
    const action = checked ? "complete" : "uncomplete";

    try {
      const response = await axios.post(`api/v1/goals/${goal.id}/${action}.json`, {}, authHeaders(token));
      props.onUpdate(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      {goals.map((goal, i) => <GoalSegment goal={goal} onToggle={toggleGoal} onDelete={deleteGoal} onEdit={open} key={`goal-segment-${i}`} showDelete={props.showDelete} showEdit={props.showEdit} showMetadata={props.showMetadata} />)}
      {goals.length === 0 && <Segment attached>No goals yet.</Segment>}
      {currentGoal && <GoalModal goal={currentGoal} onClose={close} onSave={save} />}
    </Fragment>
  );
};

export default GoalsList;
