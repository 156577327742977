import React, { useState } from 'react';
import { Container, Segment, Form, Message } from 'semantic-ui-react';
import { useAuth0 } from "../react-auth0-spa";
import ResponsiveContainer from '../components/ResponsiveContainer';
import PageHeader from '../components/PageHeader';
import { create } from '../services/supportRequestsService';

const Support = (props) => {
  const [supportRequest, setSupportRequest] = useState({
    type: '',
    subject: '',
    message: '',
  });
  const [error, setError] = useState();
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const { getTokenSilently } = useAuth0();

  const typeOptions = [
    { key: "b", text: "Bug", value: "bug" },
    { key: "r", text: "Feature Request", value: "feature" },
    { key: "f", text: "Feedback", value: "feedback" },
  ];

  const errorFor = (field) => {
    if (!error || !error[field]) {
      return;
    }

    return error[field].join(", ");
  }

  const handleChange = (_event, { name, value }) => {
    setSupportRequest({
      ...supportRequest,
      [name]: value
    });
  }

  const submit = async (supportRequest) => {
    setSending(true);
    const token = await getTokenSilently();

    try {
      await create(supportRequest, token);
      setSupportRequest({type: '', subject: '', message: ''});
      setError();
      setMessage("Successfully submitted your support request.");
    } catch (error) {
      setError(error.response.data);
      setMessage("");
      console.log(error);
    }

    setSending(false);
  }

  return (
    <ResponsiveContainer {...props}>
      <Container>
        <PageHeader icon="help" title="Support" subtitle="Use the form below to get support or to submit feature requests." />

        <Segment.Group raised>
          <Segment attached color="blue">
            <Form onSubmit={() => submit(supportRequest)} loading={sending}>
              {message && <Message positive>{message}</Message>}
              <Form.Select
                label="Type"
                name="type"
                options={typeOptions}
                value={supportRequest.type}
                onChange={handleChange}
                error={errorFor("type")}
              />
              <Form.Input
                label="Subject"
                name="subject"
                value={supportRequest.subject}
                onChange={handleChange}
                error={errorFor("subject")}
              />
              <Form.TextArea
                label="Message"
                name="message"
                value={supportRequest.message}
                onChange={handleChange}
                error={errorFor("message")}
              />
              <Form.Button primary disabled={sending}>Submit</Form.Button>
            </Form>
          </Segment>
        </Segment.Group>
      </Container>
    </ResponsiveContainer>
  )
};

export default Support;
