import React, { Fragment, useState, useEffect } from 'react';
import { Header, Loader, Segment, Label, Grid, Icon, Message } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import { useAuth0 } from "../../react-auth0-spa";
import { authHeaders } from '../../lib/authorization';
import { Link } from 'react-router-dom';

const Activity = (props) => {
  const [activity, setActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchReport() {
      const token = await getTokenSilently();

      try {
        const response = await axios.get(`api/v1/practices/activity.json`, authHeaders(token));
        setActivity(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    }

    fetchReport();
  }, [getTokenSilently]);

  const labelColor = (currentDay) => {
    const element = activity[currentDay.format("YYYY-MM-DD")];
    if (element && element > 0) {
      return "red";
    }

    if (currentDay.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      return "grey";
    }

    return null;
  }

  const daysPractice = () => {
    return Object.keys(activity).length;
  }

  const summary = () => (
    <strong>In the last week, you've practiced on {daysPractice()} different days.</strong>
  );

  const weeklyActivity = () => {
    const days = [];
    const headers = [];

    for (let i = 0; i < 7; i++) {
      let currentDay = moment().add(-6, 'days').add(i, 'day');
      let weekday = currentDay.format("ddd");
      let header = <Grid.Column key={`header-${weekday}`} textAlign="center">{weekday}</Grid.Column>;
      headers.push(header);

      let label = <Label circular size="large" color={labelColor(currentDay)}>{currentDay.format("DD")}</Label>;
      let day = <Grid.Column key={`day-${weekday}`} textAlign="center">{label}</Grid.Column>;
      days.push(day);
    }

    return <Grid columns={7}><Grid.Row>{headers}</Grid.Row><Grid.Row>{days}</Grid.Row></Grid>;
  }

  return (
    <Fragment>
      <Loader active={isLoading}></Loader>
      <Segment.Group raised>
        <Segment attached color="blue">
          <Header>
            <Link to="/insights">Activity <Icon name="arrow alternate circle right outline" /></Link>
          </Header>
        </Segment>
        {daysPractice() > 0 &&
          <Segment attached>
            {summary()}
          </Segment>
        }
        <Segment attached>
          {weeklyActivity()}
        </Segment>
        <Message warning attached='bottom'>
          <Icon name='warning' />
          You can use the <Link to="/journal">Journal</Link> to enter today's practice time.
        </Message>
      </Segment.Group>
    </Fragment>
  )
}
export default Activity;
