import axios from "axios";
import { authHeaders } from "../lib/authorization";

export const all = (token) => {
  return axios.get(`api/v1/pieces.json`, authHeaders(token))
}

export const create = (piece, token) => {
  const data = {
    piece: {
      name: piece.name,
      composer: piece.composer,
    }
  };

  return axios.post(`api/v1/pieces.json`, data, authHeaders(token))
}
