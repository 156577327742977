import React from 'react';
import TimeAgo from 'react-timeago';
import { Segment, Checkbox, List, Icon, Button } from 'semantic-ui-react';

const GoalSegment = (props) => {
  const goal = props.goal;
  const showDelete = props.showDelete === undefined ? true : props.showDelete;
  const showEdit = props.showEdit === undefined ? true : props.showEdit;
  const showMetadata = props.showMetadata === undefined ? true : props.showMetadata;

  const completedLabel = (goal) => {
    if (!goal.completed_at) {
      return;
    }

    return (
      <List.Item>
        <Icon.Group>
          <Icon color='green' name="calendar" />
          <Icon color='green' corner name="check" />
        </Icon.Group>
        <TimeAgo date={goal.completed_at} />
      </List.Item>
    );
  }

  return (
    <Segment attached key={`goal-${goal.id}`} clearing>
      <Checkbox label={goal.name} onChange={(_, { checked }) => props.onToggle(goal, checked)} checked={!!goal.completed_at} />
      {showDelete && <Button icon="delete" basic floated='right' circular size="small" onClick={() => props.onDelete(goal)} />}
      {showEdit && <Button icon="pencil" basic floated='right' circular size="small" onClick={() => props.onEdit(goal)} />}
      {showMetadata &&
        <List bulleted horizontal className="goals-list-meta" floated="right">
          <List.Item>
            <Icon name="calendar" color='purple' />
            <TimeAgo date={goal.created_at} />
          </List.Item>
          {completedLabel(goal)}
        </List>
      }
    </Segment>
  );
};

export default GoalSegment;
