import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { Segment, Loader, Header, Icon } from 'semantic-ui-react';
import { useAuth0 } from '../../react-auth0-spa';
import GoalsList from '../GoalsList';
import "./Goals.css";
import { authHeaders } from '../../lib/authorization';
import { updateWithItem } from '../../lib/filter';

const Goals = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchGoals() {
      const token = await getTokenSilently();

      try {
        const response = await axios.get(`api/v1/goals.json`, authHeaders(token));
        setGoals(response.data.filter(x => !x.completed_at));
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    }

    fetchGoals();
  }, [getTokenSilently]);

  const onUpdate = (goal) => {
    const newGoals = goals.map(updateWithItem(goal));
    setGoals(newGoals);
  }

  if (isLoading) {
    return <Loader active />;
  }

  return (
    <Segment.Group raised>
      <Segment attached color='blue'>
        <Header>
          <Link to="/goals">Your Goals <Icon name="arrow alternate circle right outline" /></Link>
        </Header>
      </Segment>
      <GoalsList goals={goals} onUpdate={onUpdate} showDelete={false} showEdit={false} />
    </Segment.Group>
  )
}
export default Goals;
