import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';

const PieceModal = (props) => {
  const [piece, setPiece] = useState(props.piece);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
  }, [props.error])

  const handleChange = (_event, { name, value }) => {
    setPiece({
      ...piece,
      [name]: value
    });
  }

  const errorFor = (field) => {
    if (!error || !error[field]) {
      return;
    }

    return error[field].join(", ");
  }

  return (
    <Modal
      open={!!piece}
      closeOnEscape
      closeOnDimmerClick
      onClose={props.onClose}
      size="small"
    >
      <Modal.Header>{piece.id ? "Update" : "New"} Piece</Modal.Header>
      <Modal.Content>
        <Form onSubmit={() => props.onSave(piece)}>
          <Form.Group widths="equal">
          <Form.Input
            label="Name"
            name="name"
            value={piece.name}
            onChange={handleChange}
            error={errorFor("name")}
          />
          <Form.Input
            label="Composer"
            name="composer"
            value={piece.composer}
            onChange={handleChange}
            error={errorFor("composer")}
          />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => props.onSave(piece)}>Save</Button>
        <Button onClick={props.onClose}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PieceModal;
