import React from 'react';
import { Loader, Segment } from "semantic-ui-react";
import Routes from "./Routes";
import { useAuth0 } from "./react-auth0-spa";
import './App.css';

function App() {
  const { loading } = useAuth0();

  let rollbar;
  if (process.env.NODE_ENV === 'production') {

  }

  if (loading) {
    return (
      <div>
        <Segment padded='very' basic>
          <Loader indeterminate active inline='centered'>Authenticating...</Loader>
        </Segment>
      </div>
    );
  }

  return (
    <Routes rollbar={rollbar} />
  );
};

export default App;
