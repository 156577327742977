import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "../react-auth0-spa";
import { authHeaders } from "../lib/authorization";

const NotesContext = createContext({});

export const NotesConsumer = NotesContext.Consumer;
export const useNotes = () => useContext(NotesContext);

export const NotesProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [noteOptions, setNoteOptions] = useState([]);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchNotes() {
      const token = await getTokenSilently();

      try {
        const response = await axios.get('api/v1/notes.json', authHeaders(token));
        const options = response.data.map((value) => {
          return {
            key: value.id,
            text: value.name,
            value: value.id,
          };
        });

        setNotes(response.data);
        setNoteOptions(options);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    fetchNotes();
  }, [getTokenSilently]);

  return (
    <NotesContext.Provider
      value={{notes, noteOptions, isLoading}}>
      {props.children}
    </NotesContext.Provider>
  );
}
