import React, { useState, useEffect } from 'react';
import { Button, Container, Loader, Header, Segment, Grid } from 'semantic-ui-react';
import { useAuth0 } from "../react-auth0-spa";
import GoalsList from '../components/GoalsList';
import GoalModal from '../components/GoalModal';
import ResponsiveContainer from '../components/ResponsiveContainer';
import { excludeById, updateWithItem } from '../lib/filter';
import { all, create } from '../services/goalsService';
import PageHeader from '../components/PageHeader';

const Goals = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const [currentGoal, setCurrentGoal] = useState();
  const [error, setError] = useState();
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchGoals = async () => {
      const token = await getTokenSilently();

      try {
        const response = await all(token);
        setGoals(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    }

    fetchGoals();
  }, [getTokenSilently]);

  const onDelete = (goalId) => {
    const newGoals = goals.filter(excludeById(goalId));
    setGoals(newGoals);
  }

  const onUpdate = (goal) => {
    const newGoals = goals.map(updateWithItem(goal));
    setGoals(newGoals);
  }

  const onSave = async (goal) => {
    const token = await getTokenSilently();

    try {
      const response = await create(goal, token);
      const newGoals = goals.concat(response.data);
      setGoals(newGoals);
      setCurrentGoal();
      setError();
    } catch (error) {
      setError(error.response.data);
      console.log(error);
    }
  }

  return (
    <ResponsiveContainer {...props}>
      <Container>
        <PageHeader icon="target" title="Goals" subtitle="Set new goals to stay on track and reflect on your achievements." />

        <Loader active={isLoading}></Loader>

        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Segment.Group raised>
                <Segment attached color='blue'>
                  <Header>Current Goals</Header>
                </Segment>
                <GoalsList goals={goals.filter(x => !x.completed_at)} onDelete={onDelete} onUpdate={onUpdate} />
                <Segment attached textAlign="center">
                  <Button icon='add' content='New Goal' labelPosition='left' primary onClick={() => setCurrentGoal({ name: '' })} />
                  {currentGoal && <GoalModal goal={currentGoal} onClose={() => setCurrentGoal()} onSave={onSave} error={error} />}
                </Segment>
              </Segment.Group>
            </Grid.Column>

            <Grid.Column width={8}>
              <Segment.Group raised>
                <Segment attached color='blue'>
                  <Header>Achieved Goals</Header>
                </Segment>
                <GoalsList goals={goals.filter(x => x.completed_at)} onDelete={onDelete} onUpdate={onUpdate} />
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Container>
    </ResponsiveContainer>
  )
};

export default Goals;
