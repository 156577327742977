import React, { Fragment, useState, useRef } from 'react';
import { Container, Segment, Loader, Button, Dropdown, Label } from 'semantic-ui-react';
import axios from "axios";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { useAuth0 } from "../react-auth0-spa";
import { findById } from "../lib/find";
import { COLOR_NAMES } from "../lib/colors";
import { usePracticeTypes } from "../contexts/PracticeTypesContext";
import { authHeaders } from '../lib/authorization';
import PageHeader from '../components/PageHeader';

const Timer = (props) => {
  const [practiceTypeId, setPracticeTypeId] = useState();
  const [practiceSessions, setPracticeSessions] = useState([]);
  const [running, setRunning] = useState(false);
  const [updater, setUpdater] = useState();
  const practiceSessionsRef = useRef(practiceSessions);
  const { getTokenSilently } = useAuth0();
  const { isLoading, practiceTypes, practiceTypeOptions } = usePracticeTypes();

  const start = () => {
    const newSession = {
      duration: 0,
      durationFormatted: "0:00",
      practiceTypeId: practiceTypeId,
      startTime: new Date().getTime(),
    };

    const newUpdater = setInterval(() => {
      let sessions = practiceSessionsRef.current;
      const currentPracticeSession = sessions[sessions.length - 1];
      const now = new Date().getTime();
      const duration = (now - currentPracticeSession.startTime);

      const newPracticeSession = {
        ...currentPracticeSession,
        duration,
        durationFormatted: millisToMinutesAndSeconds(duration)
      };

      const newPracticeSessions = sessions.map((element, index) => {
        if (index === sessions.length - 1) {
          return newPracticeSession;
        }

        return element;
      });

      setPracticeSessions(newPracticeSessions);
      practiceSessionsRef.current = newPracticeSessions;
    }, 1000);

    let newPracticeSessions = [...practiceSessions];
    newPracticeSessions.push(newSession);

    practiceSessionsRef.current = newPracticeSessions;
    setRunning(true);
    setPracticeSessions(newPracticeSessions);
    setUpdater(newUpdater);
  }

  const stop = async () => {
    clearInterval(updater);

    const currentPracticeSession = practiceSessions[practiceSessions.length - 1];
    const durationInMins = parseInt(currentPracticeSession.duration / 60000);

    if (durationInMins <= 0) {
      setUpdater(undefined);
      setPracticeTypeId(undefined);
      setRunning(false);
      setPracticeSessions(practiceSessions.slice(0, -1));

      return;
    }

    const newPracticeSession = {
      ...currentPracticeSession,
      endTime: new Date().getTime()
    };

    const newPracticeSessions = practiceSessions.map((element, index) => {
      if (index === practiceSessions.length - 1) {
        return newPracticeSession;
      }

      return element;
    });

    const data = {
      practice: {
        practice_type_id: practiceTypeId,
        duration: durationInMins
      }
    };

    const token = await getTokenSilently();
    try {
      await axios.post('api/v1/practices.json', data, authHeaders(token));
      setUpdater(undefined);
      setPracticeTypeId(undefined);
      setRunning(false);
      setPracticeSessions(newPracticeSessions);
    } catch (error) {
      console.log(error);
    }
  }

  const millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  const handleChange = (ev, { name, value }) => {
    if (name === "practiceTypeId") setPracticeTypeId(value);
  }

  return (
    <ResponsiveContainer {...props}>
      <Container>
        <PageHeader icon="stopwatch" title="Practice Timer" subtitle="Accurately track your practice time." />

        <Loader active={isLoading}></Loader>
        <Segment.Group raised>
          <Segment attached color="blue">
            Use the form below to track your practice time accurately.
            </Segment>
          {practiceSessions.map((session, index) => (
            <Segment attached key={`segment-${index}`}>
              <Label ribbon color={COLOR_NAMES[findById(practiceTypes, session.practiceTypeId).name]}>
                {findById(practiceTypes, session.practiceTypeId).display_name}
              </Label>
              <Label size="medium">
                Duration
                  <Label.Detail>{session.durationFormatted}</Label.Detail>
              </Label>
              {!session.endTime &&
                <Fragment>
                  &nbsp;<Button onClick={stop}>Stop</Button>
                </Fragment>
              }
            </Segment>
          ))}
          {!running &&
            <Segment attached>
              <Dropdown
                name="practiceTypeId"
                placeholder="Select Type"
                options={practiceTypeOptions}
                value={practiceTypeId}
                onChange={handleChange}
                selection
              />

              &nbsp;<Button onClick={start} disabled={!practiceTypeId}>Start</Button>
            </Segment>
          }
        </Segment.Group>
      </Container>
    </ResponsiveContainer>
  );
}

export default Timer;
