export function excludeById(id) {
  return function (value) {
    return value.id !== id;
  }
}

export function updateWithResponse(response) {
  return function (element) {
    if (element.id === response.data.id) {
      return response.data;
    }

    return element;
  }
}

export function updateWithItem(item) {
  return function (element) {
    if (element.id === item.id) {
      return item;
    }

    return element;
  }
}

export function addToCollection(collection, newEntry, fieldToMatch) {
  let newCollection = {};
  const keys = Object.keys(collection);

  if (keys.includes(newEntry[fieldToMatch])) {
    for (const key of keys) {
      let entries = collection[key];

      if (key === newEntry[fieldToMatch]) {
        entries = entries.concat(newEntry);
      }
      newCollection[key] = entries;
    }
  } else {
    newCollection = {
      ...collection,
      [newEntry[fieldToMatch]]: [newEntry]
    }
  }

  return newCollection;
}

export function updateItemInCollection(collection, item) {
  const newCollection = {};
  const keys = Object.keys(collection);
  for (const key of keys) {
    const entries = collection[key];
    const newEntries = entries.map(updateWithItem(item));
    newCollection[key] = newEntries;
  }

  return newCollection;
}

export function deleteFromCollection(collection, id) {
  const newCollection = {};
  const keys = Object.keys(collection);
  for (const key of keys) {
    const entries = collection[key];
    const newEntries = entries.filter(excludeById(id));
    newCollection[key] = newEntries;
  }

  return newCollection;
}
