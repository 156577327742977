import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Loader, Header, Segment, Icon, Message } from 'semantic-ui-react';
import axios from 'axios';
import { useAuth0 } from "../../react-auth0-spa";
import PracticeSegment from '../PracticeSegment';
import './Practice.css';
import { authHeaders } from '../../lib/authorization';
import { usePracticeTypes } from "../../contexts/PracticeTypesContext";
import { PiecesProvider } from "../../contexts/PiecesContext";

const Practice = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [practices, setPractices] = useState([]);
  const { getTokenSilently } = useAuth0();
  const { practiceTypes } = usePracticeTypes();

  useEffect(() => {
    async function fetchToday() {
      const token = await getTokenSilently();

      try {
        const response = await axios.get('api/v1/practices/today.json', authHeaders(token));
        setPractices(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    };

    fetchToday();
  }, [getTokenSilently]);

  const totalDuration = (practices) => {
    return practices.reduce((a, b) => a + b.duration, 0);
  }

  const renderPractices = (practices) => {
    if (isLoading) {
      return;
    }

    return (
      <PiecesProvider>
        <Segment.Group raised>
          <Segment attached color='blue'>
            <Header>
              <Link to="/journal">Daily Practice <Icon name="arrow alternate circle right outline" /></Link>
            </Header>
          </Segment>
          {practices.length > 0 && renderPracticeList(practices, practiceTypes)}
          {practices.length > 0 ?
            <Message warning attached='bottom'>
              <Icon name='warning' />
              Today, you have practiced for a total of {totalDuration(practices)} minutes.
            </Message>
            :
            <Message warning attached='bottom'>
              <Icon name='warning' />
              You can use the <Link to="/journal">Journal</Link> to enter today's practice time.
            </Message>
          }
        </Segment.Group>
      </PiecesProvider>
    );
  }

  const renderPracticeList = (practices, practiceTypes) => {
    return practices.map((practice, i) =>
      <PracticeSegment practice={practice} practiceTypes={practiceTypes} key={`practice-${i}`} showEdit={false} showDelete={false} />
    );
  }

  return (
    <Fragment>
      {renderPractices(practices)}
      <Loader active={isLoading}></Loader>
    </Fragment>
  )
}
export default Practice;
