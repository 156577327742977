import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Goals from "./pages/Goals";
import Pieces from "./pages/Pieces";
import PracticeJournal from "./pages/PracticeJournal";
import Timer from "./pages/Timer";
import Support from "./pages/Support";
import Insights from "./pages/Insights";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./components/PrivateRoute";

export default ({ childProps }) =>
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/" exact component={Home} props={childProps} />
      <PrivateRoute path="/goals" exact component={Goals} props={childProps} />
      <PrivateRoute path="/your-week" component={PracticeJournal} props={childProps} />
      <PrivateRoute path="/journal" component={PracticeJournal} props={childProps} />
      <PrivateRoute path="/timer" component={Timer} props={childProps} />
      <PrivateRoute path="/support" component={Support} props={childProps} />
      <PrivateRoute path="/pieces" component={Pieces} props={childProps} />
      <PrivateRoute path="/insights" component={Insights} props={childProps} />

      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>;
