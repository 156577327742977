import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Goals, Practice, Activity } from '../components/dashboard';
import { PracticeTypesProvider } from "../contexts/PracticeTypesContext";
import PageHeader from '../components/PageHeader';

const Home = (props) => {
  return (
    <ResponsiveContainer {...props}>
      <PracticeTypesProvider>
        <Container>
          <PageHeader icon="home" title="Home" subtitle="Get an overview of your goals and practice." />
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8} className="goals">
                <Goals />
              </Grid.Column>
              <Grid.Column width={8} className="practice">
                <Practice />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} className="reports">
                <Activity />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </PracticeTypesProvider>
    </ResponsiveContainer>
  );
}

export default Home;
