export const COLOR_NAMES = {
  exercise: 'yellow',
  scale: 'red',
  piece: 'green',
  theory: 'blue',
  sight_reading: 'purple',
  other: 'orange'
};

export const COLOR_CODES = {
  exercise: '#f1c40f',
  scale: '#c0392b',
  piece: '#27ae60',
  theory: '#2980b9',
  sight_reading: '#8e44ad',
  other: '#f39c12'
};
