import axios from "axios";
import { authHeaders } from "../lib/authorization";
import moment from "moment";

export const week = (date, token) => {
  return axios.get(`api/v1/your_week/${date.format("YYYY-MM-DD")}.json`, authHeaders(token));
}

export const isInTheFuture = (day) => {
  return moment().diff(day) < 0;
}

export const isToday = (day) => {
  return day.isSame(moment(), 'd');
}
