import React from "react";
import { Header, Icon } from "semantic-ui-react";

const PageHeader = (props) => {
  return (
    <Header size="medium">
      <Icon name={props.icon} />
      <Header.Content>
        {props.title}
        {props.subtitle && <Header.Subheader>{props.subtitle}</Header.Subheader>}
      </Header.Content>
    </Header>
  );
}

export default PageHeader;
